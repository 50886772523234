import React from 'react'
import PropTypes from 'prop-types'
import HamburgerMenu from 'react-hamburger-menu'

export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    }
  }

  handleClick() {
    this.setState({open: !this.state.open})

  }

  _renderMenuItems() {
    return(
      this.props.menuItems.map((menuItem, index) => {
        return (
          <li key={index}><a href={menuItem.path}>{menuItem.menu_title.toUpperCase()}</a></li>
        )
      })
    )
  }

  _renderMenu() {
    return(
      <div className="menu-box menu-open">
        <div className="menu-item">
          <HamburgerMenu
            isOpen={this.state.open}
            menuClicked={this.handleClick.bind(this)}
            width={45}
            height={48}
            strokeWidth={5}
            rotate={0}
            color='#fff'
            borderRadius={0}
            animationDuration={0.5}
          />
        </div>
        <div className="menu-item">
          <ul>
            {this._renderMenuItems()}
            <li><a href="/blog">BLOG</a></li>
            <li><a href="mailto:info@honeywellevents.com">CONTACT</a></li>
          </ul>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.open) {
      return this._renderMenu()
    } else {
      return (
        <div className="menu-box">
          <div className="menu-item">
            <HamburgerMenu
              isOpen={this.state.open}
              menuClicked={this.handleClick.bind(this)}
              width={45}
              height={48}
              strokeWidth={5}
              rotate={0}
              color='#F16924'
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
        </div>
      );
    }
  }
}

Menu.propTypes = {
  menuItems: PropTypes.array,
  locale: PropTypes.string
}
