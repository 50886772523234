import React from 'react'
import PropTypes from 'prop-types'
import Slider from "react-slick";

export default class CaseStudySlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }

  }

  _renderPhotos() {
    return this.props.photos.map((photo, index) => {
      return (
        <div key={index} className="case-study-slider--image-container">
          <picture>
            <source media="(min-width: 640px)" sizes="(min-width: 640px)" srcSet={`${photo.web1} 1x, ${photo.web2} 2x`} />
            <source media="(max-width: 640px)" sizes="(max-width: 640px)" srcSet={`${photo.mob1} 1x, ${photo.mob2} 2x`} />
            <img src={photo.web1} />
          </picture>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <Slider {...this.state.settings}>
          {this._renderPhotos()}
        </Slider>
      </div>
    );
  }
}

CaseStudySlider.propTypes = {
  photos: PropTypes.array
}
