import React from 'react'
import PropTypes from 'prop-types'
import ImageAudioVisual from '../assets/HWE_audio_visual_experience.jpg'
import ImageBespoke from '../assets/HWE_bespoke_entert_experience.jpg'
import ImageConference from '../assets/HWE_conference_experience.jpg'
import ImageCulinary from '../assets/HWE_culinary_experience.jpg'
import ImageMotivation from '../assets/HWE_motivation_experience.jpg'
import ImageDefault from '../assets/HWE_default_experience.jpg'
import Fade from 'react-reveal/Fade'
import ServicesItem from './ServicesItem'

export default class ServicesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSection: '',
      currentImage: ImageDefault
    }

    this.hoverAnimate = this.hoverAnimate.bind(this)
  }

  hoverAnimate = (section) => () => {
    const sectionToImage = {
      audioVisual: ImageAudioVisual,
      bespoke: ImageBespoke,
      conference: ImageConference,
      culinary: ImageCulinary,
      motivation: ImageMotivation,
    }

    this.setState({currentSection: section, currentImage: sectionToImage[section]})
  }

  _isItHovering(service) {
    return service === this.state.currentSection;
  }

  _isItHoveringSomethingElse(service) {
    return (service !== this.state.currentSection) && (this.state.currentSection !== '');
  }

  render() {
    return (
      <div className="container--services">
        <div className="container--services-imageaside">
          <Fade left>
            <img src={this.state.currentImage} />
          </Fade>
        </div>
        <Fade right>
          <div className="container--services-aside">
            <div className="container--services--text-inner">
                <h2>{this.props.title}</h2>
                <ul>
                  <Fade right>
                    <ServicesItem
                      hovering={this._isItHovering('audioVisual')}
                      hoveringOther={this._isItHoveringSomethingElse('audioVisual')}
                      text={this.props.audio_visual_title}
                      onMouseOver={this.hoverAnimate('audioVisual')}
                      blob={this.props.audio_visual_text}
                    />
                    <ServicesItem
                      hovering={this._isItHovering('bespoke')}
                      hoveringOther={this._isItHoveringSomethingElse('bespoke')}
                      text={this.props.bespoke_title}
                      onMouseOver={this.hoverAnimate('bespoke')}
                      blob={this.props.bespoke_text}
                    />
                    <ServicesItem
                      hovering={this._isItHovering('conference')}
                      hoveringOther={this._isItHoveringSomethingElse('conference')}
                      text={this.props.conference_title}
                      onMouseOver={this.hoverAnimate('conference')}
                      blob={this.props.conference_text}
                    />
                    <ServicesItem
                      hovering={this._isItHovering('culinary')}
                      hoveringOther={this._isItHoveringSomethingElse('culinary')}
                      text={this.props.culinary_title}
                      onMouseOver={this.hoverAnimate('culinary')}
                      blob={this.props.culinary_text}
                    />
                    <ServicesItem
                      hovering={this._isItHovering('motivation')}
                      hoveringOther={this._isItHoveringSomethingElse('motivation')}
                      text={this.props.motivation_title}
                      onMouseOver={this.hoverAnimate('motivation')}
                      blob={this.props.motivation_text}
                    />
                  </Fade>
                </ul>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

ServicesContainer.propTypes = {
  title: PropTypes.string,
  audio_visual_title: PropTypes.string,
  audio_visual_text: PropTypes.string,
  bespoke_title: PropTypes.string,
  bespoke_text: PropTypes.string,
  conference_title: PropTypes.string,
  conference_text: PropTypes.string,
  culinary_title: PropTypes.string,
  culinary_text: PropTypes.string,
  motivation_title: PropTypes.string,
  motivation_text: PropTypes.string,
}
