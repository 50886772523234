import React from 'react'
import PropTypes from 'prop-types'
import Slider from "react-slick";

export default class HeroSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
      },
      sliderHeading: {
        en: "WE ARE",
        ru: "МЫ"
      },
      sliderText: [
        {
          en: "a team of young creative professionals working with the best people in Cyprus to capture, design and deliver creative and ingenious concepts and ideas for your events!",
          ru: "это команда творческих молодых профессионалов, сотрудничающая с лучшими людьми Кипра для создания и воплощения в жизнь самых оригинальных концепций и идей ваших мероприятий!"
        },
        {
          en: "in control of every detail with regards to your event! Providing a complete package of services we guarantee our partners a complete peace of mind!",
          ru: "Не упустит не одной детали вашего мероприятия! Предоставляя полный пакет услуг, мы стремимся оградить наших клиетов от забот и стресса!"
        },
        {
          en: "in the destination management business of Cyprus for 25 years! Established in 1990, Honeywell Events is today Cyprus’ leading award-winning DMC. With the experience of handling a great number of events, we provide programs to the world’ top companies.",
          ru: "успешно работает более 25 лет! Основанная в 1990 году, компания Honeywell Events является лидером на рынке корпоративного туризма Кипра и имеет опыт в организации мероприятий для известных мировых корпораций."
        },
        {
          en: "organizing local events with international impact! The team of Honeywell Events is behind the largest sports event in Cyprus, accommodating thousands of local and international runners every year!",
          ru: "Является организотором крупнейшего спортивного мероприятия Кипра – Лимассольского марафона – в котором ежегодно принимают участие десятки тысяч спортсменов из разных стран!"
        }
      ]
    }
  }

  render() {
    return (
      <div>
        <Slider {...this.state.settings}>
          <div className="container--hero-slider-1">
            <div className="container--hero-aside">
              <h2>{this.state.sliderHeading[this.props.locale]}</h2>
              <p>{this.state.sliderText[0][this.props.locale]}</p>
            </div>
          </div>
          <div className="container--hero-slider-2">
            <div className="container--hero-aside">
              <h2>{this.state.sliderHeading[this.props.locale]}</h2>
              <p>{this.state.sliderText[1][this.props.locale]}</p>
            </div>
          </div>
          <div className="container--hero-slider-3">
            <div className="container--hero-aside">
              <h2>{this.state.sliderHeading[this.props.locale]}</h2>
              <p>{this.state.sliderText[2][this.props.locale]}</p>
            </div>
          </div>
          <div className="container--hero-slider-4">
            <div className="container--hero-aside">
              <h2>{this.state.sliderHeading[this.props.locale]}</h2>
              <p>{this.state.sliderText[3][this.props.locale]}</p>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

HeroSlider.propTypes = {
  locale: PropTypes.string
}
