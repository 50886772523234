import React from 'react'
import PropTypes from 'prop-types'
import Flip from 'react-reveal/Flip'

export default class ServicesItem extends React.Component {
  _renderText() {
    if(this.props.hovering) {
      return(
        <Flip bottom>
          <li>{this.props.blob}</li>
        </Flip>
      )
    }
  }

  _renderItem() {
    if(this.props.hovering) {
      return(<li className="highlight">{this.props.text}</li>)
    } else if (this.props.hoveringOther){
      return(<li className="fade">{this.props.text}</li>)
    } else {
      return(<li>{this.props.text}</li>)
    }
  }

  render() {
    return (
      <div onMouseOver={this.props.onMouseOver} onClick={this.props.onMouseOver}>
        {this._renderItem()}
        {this._renderText()}
      </div>
    );
  }
}

ServicesItem.propTypes = {
  hovering: PropTypes.bool,
  hoveringOther: PropTypes.bool,
  text: PropTypes.string,
  blob: PropTypes.string,
  onMouseOver: PropTypes.func
}
